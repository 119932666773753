.common-wrapper {
  .form-label {
    margin-bottom: 8px;
  }
  .form-label,
  .form-control,
  .form-select,
  button,
  .rdt_TableCell {
    font-size: 16px;
    height: auto !important;
  }
}
